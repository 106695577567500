.auth {
  @apply h-[100vh] bg-white;
  background-size: auto 100%, auto 340px;
  background-color: rgb(255, 255, 255);
  background-image: url(../assets/image/purple_background.png),
    url(../assets/image/arrow_background.png);
  background-repeat: no-repeat, no-repeat;
  background-position: right top, left center;
}

@media screen and (max-width: 1024px) {
  .auth {
    background: none;
  }
}

.authContainer {
  @apply max-w-[780px] w-full my-0 mx-auto px-5;
}
