@media screen and (max-width: 992px) {
  .wrapper {
    display: flex;
    justify-content: center;
  }
}

.signIn {
  @apply px-0 pt-0 flex justify-center lg:px-[50px] lg:pt-[42px] lg:block h-full;
  max-width: 680px;
  flex-direction: column;
}

.button {
  @apply bg-apari-purple w-full text-white h-9 rounded-sm flex justify-center items-center;
  @apply max-w-[288px] lg:max-w-full mx-auto mt-[30vh] lg:mt-0;
  max-width: 288px;
  margin: 0;
}

.title {
  @apply font-apotek max-w-[240px] lg:max-w-full lg:w-full;
  @apply text-[48px] leading-[44px] lg:text-[64px] lg:leading-[56px];
}

.input {
  @apply mb-4 h-9 rounded-sm text-sm px-3 border-gray-300 border-gray-300 focus:outline-apari-purple;
  max-width: 288px;
}
