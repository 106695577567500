.table {
  @apply border border-gray-200 border-b-0 rounded-t-md;
  box-shadow: 0px 6px 6px -6px rgba(0, 0, 0, 0.25);
}

.dot {
  @apply w-2 h-2 rounded-full inline-block bg-red-500 ml-7;
}

.iconButton {
  @apply p-1.5 rounded-full hover:bg-gray-300 hover:cursor-pointer flex justify-center items-center;
}

.iconButton :global(svg) {
  @apply w-[14px] h-[14px];
}

.actionButton {
  @apply w-20 h-6 rounded text-apari-purple font-bold text-xs bg-white border-2 border-apari-purple flex justify-between items-center px-2.5;
  @apply hover:border-blue-800 hover:text-blue-800;
}
