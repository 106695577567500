@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Apotek Cond Bold';
  src: url('../font/Apotek-Cond-Bold.ttf');
}

@font-face {
  font-family: 'Roboto Medium';
  src: url('../font/Roboto-Medium.ttf');
}

@font-face {
  font-family: 'Roboto Regular';
  src: url('../font/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Telegraf';
  src: url('../font/Telegraf-Regular.otf');
}
