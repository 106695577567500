.input {
  @apply h-9 rounded-sm text-sm px-3 border-gray-300 border-gray-300 focus:outline-apari-purple min-w-[199px] placeholder:text-black;
}

.copyButton {
  @apply w-full text-white rounded-sm h-8 font-bold mb-3 flex justify-center items-center max-w-[266px];
}

.cancelButton {
  @apply w-full bg-white border-2 h-8 rounded-sm font-bold text-apari-purple border-apari-purple flex justify-center items-center;
  @apply max-w-[266px];
}
