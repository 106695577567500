.input {
  @apply h-9 rounded-sm text-sm px-3 border-gray-300 border-gray-300 focus:outline-apari-purple;
}

.submitButton {
  @apply w-full text-white rounded-sm h-8 font-bold mb-3 flex justify-center items-center;
}

.cancelButton {
  @apply w-full bg-white border-2 h-8 rounded-sm font-bold text-apari-purple border-apari-purple flex justify-center items-center;
}
