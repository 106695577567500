.tooltip {
  @apply rounded bg-black text-white py-1 px-2 absolute left-1/2 bottom-full whitespace-nowrap text-xs;
  @apply -translate-y-[10px] -translate-x-1/2;
}

.tooltip:after {
  @apply content-[''] absolute top-full left-1/2 -translate-y-1 -translate-x-1/2;
  border-top: 8px solid black;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}
