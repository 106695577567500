.sessionCard {
  @apply rounded-sm py-3 pl-5 pr-4 flex items-center justify-between mb-2.5 bg-white;
  box-shadow: 0 4px 122px rgba(0, 0, 0, 0.04), 0 1.5px 27px rgba(0, 0, 0, 0.023),
    0 0.5px 8px rgba(0, 0, 0, 0.01);
}

.cardTitle {
  @apply text-[13px] leading-[16px] font-bold;
}

.cardInfo {
  @apply text-[10px] leading-[13px] mt-0.5;
  color: rgba(0, 0, 0, 0.6);
}
