.button {
  @apply py-2 px-4 rounded inline-block;
  @apply disabled:cursor-not-allowed disabled:bg-opacity-75;
}

.button-default {
  @apply bg-slate-200;
}

.button-primary {
  @apply bg-apari-purple text-white;
}
