.checkbox {
  @apply block relative cursor-pointer text-lg w-[15px] h-[15px];
}

.checkbox :global(input:checked ~ span) {
  @apply bg-apari-purple border-apari-purple;
}

.checkmark:after,
.minusmark:after {
  @apply absolute hidden content-[''];
}

.checkbox :global(input:checked ~ span::after) {
  @apply block;
}

.checkboxInput {
  @apply absolute opacity-0 cursor-pointer h-0 w-0;
}

.mark {
  @apply absolute top-0 left-0 w-[15px] h-[15px] border-2 rounded-sm border-gray-300;
}

.checkmark:after {
  @apply transform rotate-45 left-[3px] top-[-1.5px] w-[5px] h-[10px] border border-white;
  border-width: 0 2px 2px 0;
}

.minusmark:after {
  @apply left-[1.5px] top-[4px] w-[9px] border-b-2 border-b-white;
}
